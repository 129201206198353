import { Component } from '@angular/core';

@Component({
    selector: 'info',
    templateUrl: './info.component.html'
})
export class InfoComponent {

    constructor(
    ) { }

    ngOnInit() { }

    ngOnDestroy() { }
}
